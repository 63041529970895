<template>
  <div div id="index" ref="index">
    <Loading />
    <ul class="fixed top-1/2 right-0 z-10" style="transform: translateY(-50%);">
      <li
        class="w-3 h-3 block bg-white rounded-full m-4 cursor-pointer"
        :class="{ 'bg-primary': page == index }"
        @click.prevent="$scrollTo(`#${item.name}`)"
        v-for="(item, index) in items"
        :key="index"
      ></li>
    </ul>
    <component
      v-bind:is="item.component"
      v-for="(item, index) in items"
      :key="index"
      :id="item.name"
      class="min-h-screen"
    ></component>
  </div>
</template>

<script>
import Loading from "@/components/Theme/Loading";
import Movie from "@/components/Diagnostic/Movie";
import Index from "@/components/Diagnostic/Index";
import One from "@/components/Diagnostic/One";
import Two from "@/components/Diagnostic/Two";
import Three from "@/components/Diagnostic/Three";
import Four from "@/components/Diagnostic/Four";
import Five from "@/components/Diagnostic/Five";
import Six from "@/components/Diagnostic/Six";

export default {
  name: "Diagnostic",
  data() {
    return {
      items: [
        { component: "Movie", name: "movie" },
        { component: "Index", name: "diagnostic" },
        { component: "One", name: "one" },
        { component: "Two", name: "two" },
        { component: "Three", name: "three" },
        { component: "Four", name: "four" },
        { component: "Five", name: "five" },
        { component: "Six", name: "six" }
      ],
      page: 0,
      h: 0
    };
  },
  components: {
    Loading,
    Movie,
    Index,
    One,
    Two,
    Three,
    Four,
    Five,
    Six
  },
  mounted() {
    const vm = this;
    vm.windowSize();
    window.addEventListener("resize", vm.windowSize);
    window.addEventListener("scroll", vm.onScroll);
  },
  methods: {
    windowSize() {
      this.h = window.innerHeight;
    },
    onScroll() {
      for (let i = 0; i < this.items.length; i++) {
        if (
          window.scrollY >= this.h * i - 90 &&
          window.scrollY < this.h * (i + 1) - 90
        ) {
          this.page = i;
        }
      }
      return false;
    }
  },
  beforeDestroy() {
    const vm = this;
    window.removeEventListener("resize", vm.windowSize);
    window.removeEventListener("scroll", vm.onScroll);
  }
};
</script>
