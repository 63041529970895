<template>
  <section
    class="w-full bg-cover bg-center relative flex justify-center items-center"
    :style="
      `background-image: url('${require('@/assets/img/diagnostic/movie.jpg')} ')`
    "
  >
    <div class="px-10">
      <div v-if="isMovie">
        <div class="flex justify-end">
          <button @click.prevent="closeMovie()" class="lg:-mx-16 lg:px-4 py-4">
            <svg
              class="h-8 fill-current ml-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
              />
            </svg>
          </button>
        </div>

        <youtube
          class="md:w-full"
          :video-id="videoId"
          ref="youtube"
          :fitParent="true"
          :resize="true"
          @ended="closeMovie()"
        />
      </div>
    </div>
    <div
      class="w-full absolute bottom-0 left-0 flex flex-col items-center justify-center animate__animated animate__fadeIn animate__slow"
    >
      <div class="lg:pb-32 pb-6">
        <button
          v-if="!isMovie"
          @click.prevent="openMovie()"
          class="pulse rounded-full w-24 h-24 uppercase text-gray-200 font-extrabold bg-secondary text-xl font-sans flex justify-center items-center z-20"
        >
          <svg
            class="h-20 fill-current ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M15,10.001c0,0.299-0.305,0.514-0.305,0.514l-8.561,5.303C5.51,16.227,5,15.924,5,15.149V4.852
	c0-0.777,0.51-1.078,1.135-0.67l8.561,5.305C14.695,9.487,15,9.702,15,10.001z"
            />
          </svg>
        </button>
      </div>
      <div class="text-gray-200 flex flex-col items-center w-full bg-thirdry py-5" v-if="!isMovie">
        <p class="lg:text-2xl py-2 text-center px-10 text-lg" v-html="text"></p>
        <a href="#diagnostic" v-scroll-to="'#diagnostic'">
          <svg
            class="h-10 fill-current bounce my-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M13.418,7.859c0.271-0.268,0.709-0.268,0.978,0c0.27,0.268,0.272,0.701,0,0.969l-3.908,3.83
	c-0.27,0.268-0.707,0.268-0.979,0l-3.908-3.83c-0.27-0.267-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.978,0L10,11L13.418,7.859z
	"
            />
          </svg>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Movie",
  data() {
    return {
      videoId: "Ayn7EqCZhv0",
      isMovie: false,
      text: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    openMovie() {
      this.isMovie = true;
      setTimeout(() => {
        this.player.playVideo();
      }, 300);
    },
    closeMovie() {
      this.isMovie = false;
      this.$scrollTo("#diagnostic");
    },
    async fetchData() {
      await axios.get("/api/app/2").then(response => {
        this.text = response.data.text;
      });
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    }
  }
};
</script>
